import { Component, Input } from '@angular/core';
import { G2gAvatarComponent } from '../../form/g2g-avatar/g2g-avatar.component';
import { G2gBtnFollowComponent } from '../g2g-btn-follow/g2g-btn-follow.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-g2g-user-tile',
  standalone: true,
  imports: [
    G2gBtnFollowComponent,
    G2gAvatarComponent
  ],
  templateUrl: './g2g-user-tile.component.html',
  styleUrl: './g2g-user-tile.component.scss'
})
export class G2gUserTileComponent {
  @Input('user') user: any;
  @Input('compact') compact: boolean = false;

  constructor(private router: Router) { }

  visitProfile(userId: string) {
    if (userId) {
      this.router.navigateByUrl(`player-profile/${userId}`);
    }
  }
}
